import { Component, OnInit } from '@angular/core';
import { LoadingService } from './loading.service';

@Component({
	selector: 'app-loading',
	templateUrl: './loading.component.html',
	styleUrls: ['./loading.component.css']
})
export class LoadingComponent implements OnInit {

	public showLoading: boolean = false
	public showPercentage: boolean = false
	public showLoadingMessage: boolean = false
	public showCancelButton: boolean = false

	public percentageValue: number = 0
	public loadingMessage: string = ''

	private readonly TIMEOUT_DURATION = 60000; // Define global timeout duration
	private cancelButtonTimeout: any; // Store the timeout reference

	constructor(private loadingService: LoadingService) { }

	ngOnInit() {
	}

	ngAfterContentInit() {
		//Called after ngOnInit when the component's or directive's content has been initialized.
		//Add 'implements AfterContentInit' to the class.
		this.loadingService.showLoadingExecute().subscribe(option => this.showLoadingPanel(option))
		this.loadingService.hideLoadingExecute().subscribe(option => this.hideLoadingPanel())
		this.loadingService.changeLoadingMessageExecute().subscribe(option => this.changeMessage(option))
		this.loadingService.changeLoadingPercentageExecute().subscribe(option => this.changePercentage(option))
	}

	private showLoadingPanel(option: any) {
		if(option.showMessage) {
			this.showLoadingMessage = true
			this.loadingMessage = option.initialMessage
		} else {
			this.showLoadingMessage = false
			this.loadingMessage = ''
		}

		if(option.showPercentage) {
			this.showPercentage = true;
			this.percentageValue = option.initialPercentage
		} else {
			this.showPercentage = false;
			this.percentageValue = 0
		}
		
		this.showLoading = true

		if (this.cancelButtonTimeout) {
			clearTimeout(this.cancelButtonTimeout);
			this.cancelButtonTimeout = null;
		}
		this.cancelButtonTimeout = setTimeout(() => {
			this.showCancelButton = true;
		  }, this.TIMEOUT_DURATION);
	}

	private hideLoadingPanel() {
		this.showLoading = false 
		this.resetLoadinPanel()
	}

	private resetLoadinPanel() {
		this.showPercentage = false
		this.showLoadingMessage = false 
		if (this.cancelButtonTimeout) {
			clearTimeout(this.cancelButtonTimeout);
			this.cancelButtonTimeout = null;
		}
		this.showCancelButton = false
		this.percentageValue = 0
		this.loadingMessage = ''
	}

	private changeMessage(option: any) {
		let message = option.message
		this.loadingMessage = message
	}

	private changePercentage(option: any) {
		let percentage = option.percentage
		this.percentageValue = percentage
	}
}
